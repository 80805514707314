import { notify } from '@kyvg/vue3-notification'
import dayjs from 'dayjs'
import MobileDetect from 'mobile-detect'
import type { ComputedRef, Ref } from 'vue'
import { defineStore } from 'pinia'
import { isCompanyIp as checkCompanyIp, type ImageSet } from '../composables/common'
import { useDYAppConfig } from '../composables/config'
import { useDYFetch } from '../composables/fetch'
import { useDYMainStore } from './main'

class DYUserStoreError extends Error {
}

interface User {
  no: number
  id: string
  name: string
  nickname?: string
  // picture: string
  pictureSet: ImageSet
  phone: string
  adminPhoto?: string
  email: string
  vipGrade: number
  isoCode2?: string
  scope: string[]
  pcsCertify?: boolean
  /**
   * 차단된 다른 사용자. 이 목록에 있는 사용자의 컨텐츠는 노출되지 않아야 한다.
   */
  blockedUsers?: number[]
  ssrStatisticsData?: any

  zip?: string
  address1?: string
  address2?: string
  countUnreadMessage?: number
  /**
   * 자체 로그인 가능한지 여부 (oauth가입자가 아니거나, oauth가입했지만 비번 바꾼 경우)
   */
  isAbleDirectLogin?: boolean
}

interface TokenInfo {
  expiration: Date
  token: string
  refreshToken?: string
}

export function getOrFail<V>(value: Ref<V | undefined>): ComputedRef<V> {
  return computed(() => {
    if (value.value === undefined) {
      throw new DYUserStoreError('아직 준비되지 않은 기능입니다.')
    }
    return value.value
  })
}

export const useDYUserStore = defineStore('nuxt3-module-doyac-user', () => {
  const accessToken = ref<string>()
  const accessTokenExpiration = ref<Date>()
  const browserName = ref<string>()
  const userAgent = ref<string>()
  const isIOS = ref(false)
  const isAndroidOS = ref(false)
  const isWindowsOS = ref(false)
  const isMacOS = ref(false)
  const isAndroidApp = ref(false) // TODO: 유저용 프로젝트에서 작업예정
  const isIOSApp = ref(false) // TODO: 유저용 프로젝트에서 작업예정
  const inAppLoading = ref(false) // 인앱 로딩
  const isFirstPage = ref(true)
  const countryCode = ref<string>()
  const userNo = ref<number>()
  const adminPhoto = ref<string>()
  const id = ref<string>()
  const name = ref<string>()
  const nickname = ref<string>()
  // const picture = ref<string>()
  const pictureSet = ref<ImageSet>()
  const pcsCertify = ref<boolean>()
  const phone = ref<string>()
  const email = ref<string>()
  const vipGrade = ref<number>()
  const ssrStatisticsData = ref<any>() // TODO: 유저용 프로젝트에서 작업예정
  const scope = ref<string[]>()
  const androidAppVersion = ref<number>() // TODO: 유저용 프로젝트에서 작업예정
  const iOSAppVersion = ref<number>()
  const googleInstanceId = ref<string>() // TODO: 유저용 프로젝트에서 작업예정
  const ip = ref<string>()
  const isCaptchaRequired = ref(false)
  const myPayment = ref<Record<string, string>>()
  /**
   * 읽지않은 쪽지의 개수
   */
  const countUnreadMessage = ref<number>(0)
  const isAbleDirectLogin = ref<boolean>()

  const zip = ref<string>()
  const address1 = ref<string>()
  const address2 = ref<string>()

  const isShowRightMentBanner = ref(true)
  const isShowChannelTalkBanner = ref(true)
  const lastExpirationCheckedTime = ref(new Date())

  const router = useRouter()

  /**
   * 토큰을 갱신해야하는지 판단하는 메서드.
   * @param cookie
   */
  function shouldRefreshToken() {
    const token = useCookie('auth._token.laravelPassport')
    const expiration = useCookie('auth._token_expiration.laravelPassport')

    // 리프레시 토큰의 유무는 httpOnly이므로 판단할 수 없음!
    if (token.value && expiration.value) {
      if ((new Date()).getTime() > parseInt(expiration.value)) {
        return true
      }
    }

    return !token.value || !expiration.value
  }

  async function refreshToken(): Promise<TokenInfo | null> {
    const response = await useDYFetch<Record<string, any>>('/mer/oauth/auto-refresh')

    if (response.data.value) {
      return {
        expiration: dayjs().add(response.data.value.expires_in, 'second').toDate(),
        token: `Bearer ${response.data.value.access_token}`,
        refreshToken: response.data.value.refresh_token
      }
    }

    return null
  }

  async function fetchUser(token: string): Promise<User | null> {
    const response = await useDYFetch<User>(`/mer/oauth/user`, { headers: { Authorization: `${token}` } })
    return response.data.value
  }

  function injectBrowserInfo() {
    const headers = useRequestHeaders()
    let raw = ''
    if (process.server) {
      raw = headers['user-agent']
    } else {
      raw = window.navigator.userAgent
    }

    if (!raw) { return }

    const { ANDROID_APP_PACKAGE_NAME } = useDYAppConfig()

    userAgent.value = raw

    const md = new MobileDetect(raw);
    const ua = raw.toLocaleLowerCase()
    const isSamsungBrowser = ua.search("samsungbrowser") > -1

    browserName.value = '현재 알아내지 못함'
    isIOS.value = md.os() === 'iOS'
    isAndroidOS.value = md.os() === "AndroidOS" || isSamsungBrowser
    isMacOS.value = ua.search("mac") > -1 || ua.search("macintosh") > -1
    isWindowsOS.value = ua.search("windows") > -1 && !isSamsungBrowser

    if (headers['dy-android-app-version']) {
      androidAppVersion.value = parseInt(headers['dy-android-app-version'])
    }
    googleInstanceId.value = headers['dy-google-instance-id']
    // 안드로이드 앱 판단. 안드로이드 웹뷰는 요청 헤더에 `x-requested-with`로 해당 앱의 패키지명을 보내므로 이를 통해
    // 앱에서의 요청인지 알 수 있다. 다만 2024.3.18. 기준으로 이 로직만으로는 앱인지 제대로 판명되지 않는 현상이 보고되고
    // 있다. 이를 해결하기 위해 `DYDefault`에서 onMounted 시점에 안드로이드용 자바스크립트 메서드가 있는지 판단해서 다시
    // 할당하게 하고 있음.
    isAndroidApp.value = headers['x-requested-with'] === ANDROID_APP_PACKAGE_NAME

    const iOSCookie = useCookie('dy-ios-app-version')
    isIOSApp.value = !!iOSCookie.value
    if (iOSCookie.value) {
      iOSAppVersion.value = parseInt(iOSCookie.value)
    }
  }

  async function injectUser() {
    const expiration = useCookie('auth._token_expiration.laravelPassport_mocking').value
      ? useCookie('auth._token_expiration.laravelPassport_mocking')
      : useCookie('auth._token_expiration.laravelPassport')
    const token = useCookie('auth._token.laravelPassport_mocking').value
      ? useCookie('auth._token.laravelPassport_mocking')
      : useCookie('auth._token.laravelPassport')

    const tokenInfo: TokenInfo | null = await (async () => {
      if (shouldRefreshToken()) {
        // 리프레시 토큰의 유무는 클라이언트가 판단할 수 없으므로(httpOnly) 쿠키에 있든 없든 서버로 무조건 쏴야함.
        const refreshedToken = await refreshToken()

        if (refreshedToken) { // 토큰이 갱신되면 쿠키에도 반영해야 함.
          token.value = refreshedToken.token
          expiration.value = `${refreshedToken.expiration.getTime()}`
          const strategy = useCookie('auth.strategy')
          strategy.value = 'laravelPassport'

          // 2024.9.23 김PD
          // 백엔드에서 쿠키를 굽기 때문에 굳이 필요하지 않음. 혹시 문제가 생기면 다시 체크할 것
          // if (window.android && window.android.syncToken) { // 안드로이드 앱 동기화
          //   window.android.syncToken(
          //     refreshedToken.token,
          //     refreshedToken.refreshToken ?? '',
          //     refreshedToken.expiration.getTime()
          //   )
          // }

          if (window.webkit?.messageHandlers) { // iOS 앱 동기화
            window.webkit.messageHandlers.scriptHandler?.postMessage({
              script: 'syncToken',
              params: [refreshedToken.token, refreshedToken.refreshToken]
            })
          }
        }
        return refreshedToken
      }

      if (expiration.value && token.value) {
        return {
          expiration: new Date(parseInt(expiration.value)),
          token: token.value
        }
      } else {
        return null
      }
    })()

    if (!tokenInfo) {
      return
    }

    if (new Date() >= tokenInfo.expiration) {
      return
    }

    try {
      if (tokenInfo.token) {
        const user = await fetchUser(tokenInfo.token)
        if (user) {
          accessToken.value = tokenInfo.token
          accessTokenExpiration.value = tokenInfo.expiration
          setSignedUser(user)
        }
      }
    } catch (error) {
      console.log('failed user info...', error)
    }
  }

  function setSignedUser(user: User) {
    userNo.value = user.no
    adminPhoto.value = user.adminPhoto
    id.value = user.id
    name.value = user.name
    nickname.value = user.nickname
    // picture.value = user.picture
    pictureSet.value = user.pictureSet

    phone.value = user.phone
    email.value = user.email
    vipGrade.value = user.vipGrade
    countryCode.value = user.isoCode2
    scope.value = user.scope
    pcsCertify.value = user.pcsCertify
    isAbleDirectLogin.value = user.isAbleDirectLogin

    if (user.zip) zip.value = user.zip
    if (user.address1) address1.value = user.address1
    if (user.address2) address2.value = user.address2
  }

  function clearUser() {
    accessToken.value = undefined
    accessTokenExpiration.value = undefined

    userNo.value = undefined
    adminPhoto.value = undefined
    id.value = undefined
    name.value = undefined
    nickname.value = undefined
    // picture.value = undefined
    pictureSet.value = undefined
    phone.value = undefined
    email.value = undefined
    vipGrade.value = undefined
    countryCode.value = undefined
    scope.value = undefined
    pcsCertify.value = undefined
    zip.value = undefined
    address1.value = undefined
    address2.value = undefined
  }

  async function requestLogout() {
    const mainStore = useDYMainStore()

    const mockingToken = useCookie('auth._token.laravelPassport_mocking')
    if (mockingToken.value) { // 강제 접속인 경우에는 강제 접속만 해제함
      mockingToken.value = null

      const mockingExpiration = useCookie('auth._token_expiration.laravelPassport_mocking')
      mockingExpiration.value = null

      if (process.client) {
        window.alert('강제접속 해제되었습니다.')
        location.reload()
      }
    } else {
      mainStore.isLoading = true
      const response = await useDYFetch<any>('/mer/logout')
      mainStore.isLoading = false

      const token = useCookie('auth._token.laravelPassport')
      token.value = null

      const tokenExpiration = useCookie('auth._token_expiration.laravelPassport')
      tokenExpiration.value = null

      const redirect = useCookie('auth.redirect')
      redirect.value = null

      const strategy = useCookie('auth.strategy')
      strategy.value = null

      clearUser()

      // 안드로이드나 iOS 앱의 경우 앱에서도 로그아웃되도록 앱 전용 스크립트를 호출해야함.
      if (window.android && window.android.logout) {
        window.android.logout()
      }
      if (window.webkit && window.webkit.messageHandlers.scriptHandler) {
        window.webkit.messageHandlers.scriptHandler?.postMessage({
          script: 'oAuthLogout',
          params: []
        })
      }

      notify({
        text: '성공적으로 로그아웃했습니다.'
      })
    }
  }

  /**
   * 사용자와 관련된 각종 정보를 초기화(준비)하는 메서드. SSR 환경에서 동작시키면 에러가 발생하므로 반드시 클라이언트 환경에서 동작시켜야 함.
   */
  async function init() {
    await injectUser()

    const response = await useDYFetch<string>(`/mer/api/ip`)
    if (response.error.value) {
      if (response.error.value.data?.resultCode === 'RECAPTCHA_REQUIRED') {
        isCaptchaRequired.value = true
        await router.push('verify-human')
      }
    }

    if (response.data.value) {
      ip.value = response.data.value
    }

    isPrepared.value = true
  }

  const isHuman = computed(() => {
    // if (userAgent.value && (userAgent.value.includes('naver.me/scrap') || userAgent.value.includes('Yeti'))) { // 네이버 스크랩 엔진
    //   return false
    // }

    if (userAgent.value && (userAgent.value.includes('naver.me/scrap') || userAgent.value.includes('Yeti'))) { // 네이버 스크랩 엔진
      return false
    }
    if (userAgent.value && (userAgent.value.includes('Googlebot')
      || userAgent.value.includes('Storebot-Google')
      || userAgent.value.includes('Google-InspectionTool')
      || userAgent.value.includes('GoogleOther')
      || userAgent.value.includes('Google-Extended')
      || userAgent.value.includes('APIs-Google')
      || userAgent.value.includes('AdsBot-Google')
      || userAgent.value.includes('Mediapartners-Google')
      || userAgent.value.includes('Google-Safety'))) { // 구글 스크랩 엔진
      return false
    }
    if (userAgent.value && (userAgent.value.includes('HeadlessChrome'))) { // 헤드리스 크롬. pageres 캡쳐용도일때
      return false
    }
    if (userAgent.value &&
      (userAgent.value.includes('msnbot') || userAgent.value.includes('Slurp') || userAgent.value.includes('Daumoa')
        || userAgent.value.includes('ia_archiver') || userAgent.value.includes('aolbuild') || userAgent.value.includes('teoma')
        || userAgent.value.includes('Baiduspider') || userAgent.value.includes('Twitterbot') || userAgent.value.includes('facebookexternalhit')
        || userAgent.value.includes('Facebot') || userAgent.value.includes('bingbot') || userAgent.value.includes('LinkedInBot')
        || userAgent.value.includes('Applebot') || userAgent.value.includes('Daum') || userAgent.value.includes('DuckDuckBot')
        || userAgent.value.includes('kakaotalk-scrap') || userAgent.value.includes('PetalBot'))) { // msn,yahoo,daum 등등
      return false
    }

    return isWindowsOS.value || isMacOS.value || isAndroidOS.value || isIOS.value || isAndroidApp.value || isIOSApp.value
  })

  const isCompanyIp = computed(() => ip.value ? checkCompanyIp(ip.value) : false)

  const isSignedIn = computed(() => {
    if (accessTokenExpiration.value) {
      return !!id.value && lastExpirationCheckedTime.value.getTime() < accessTokenExpiration.value.getTime()
    }
    return false
  })

  const isPrepared = ref(false)

  const ready = () => new Promise<boolean>((resolve) => {
    if (process.server) {
      throw new DYUserStoreError('SSR에서 지원되지 않는 기능입니다.')
    }

    // console.log('ready called...')
    if (isPrepared.value) {
      // console.log('userStore is already prepared... return true at once')
      resolve(true)
    } else {
      // console.log('userStore is not prepared yet... wait for preparation...')
      const off = watch(isPrepared, (value) => {
        if (value) {
          // console.log('userStore is now ready... resolve true')
          resolve(true)
          off()
        }
      })
    }
  })

  return {
    init,
    ready,
    injectBrowserInfo,
    injectUser,
    clearUser,
    requestLogout,
    /**
     * 스토어를 사용할 준비가 되었는지 여부. `init` 메서드 호출이 완료되어야 `true`가 된다.
     */
    isPrepared,
    accessToken,
    accessTokenExpiration,
    browserName,
    userAgent,
    isIOS,
    /**
     * 안드로이드 OS 여부. 클라이언트가 브라우저를 강제로 데스크탑 상태로 인식시키는 옵션을 사용하면 안드로이드로
     * 인식되지 않으므로 주의할 것. 이 경우 `isWindowsOS`, `isMacOS` 프로퍼티를 종합적으로 판단해야 한다.
     */
    isAndroidOS,
    /**
     * Mac OS 여부. User Agent에서 'mac' 문구가 포함되어 있는지를 따지는 방식이다.
     */
    isMacOS,
    /**
     * 윈도우즈 OS 여부. User Agent에서 'windows' 문구가 포함되어 있는지를 따지는 방식이다. 안드로이드에서 강제로
     * 데스크탑으로 인식시킨 경우에는 'windows' 문구가 포함되지 않아 `false`가 반환된다.
     */
    isWindowsOS,
    /**
     * 사람에 의한 요청인지 여부. 사람인지를 판단하는 기준은 OS(Windows, Mac, Android, iOS)와 앱 여부이다.
     */
    isHuman,
    /**
     * [SSR 전용. 작업예정] 안드로이드 앱 여부. 안드로이드 앱은 WebView를 사용하는데 WebView 요청인 경우 X-Requested-With 요청 헤더에 앱의
     * 패키지 이름을 함께 보내오는데 이 값의 유무를 가지고 앱 여부를 판단한다.
     */
    isAndroidApp,
    isIOSApp,
    iOSAppVersion,
    inAppLoading,
    isFirstPage,
    isCompanyIp,
    countryCode,
    isSignedIn,
    userNo,
    id,
    adminPhoto,
    name,
    nickname,
    // picture,
    pictureSet,
    pcsCertify,
    phone,
    email,
    vipGrade,
    scope,
    /**
     * [SSR 전용. 작업예정] 안드로이드 버전. 버전 코드 260 이후부터 지원
     */
    androidAppVersion,
    /**
     * [SSR 전용. 작업예정] FCM 수신에 사용되는 식별자. 보통 안드로이드 앱에서 사용하지만 iOS에서도 사용할 수
     * 있으므로 이 값의 유무를 가지고 안드로이드 앱 여부를 판단해서는 안된다.
     */
    googleInstanceId,
    ip,
    isCaptchaRequired,
    ssrStatisticsData,
    myPayment,

    zip,
    address1,
    address2,
    isShowRightMentBanner,
    isShowChannelTalkBanner,
    countUnreadMessage,
    isAbleDirectLogin,
    lastExpirationCheckedTime
  }
})